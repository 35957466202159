export default [
  {
    prop: 'id',
    label: 'STT',
    minWidth: 80,
    type: 'id'
  },
  {
    prop: 'searchable',
    label: 'Tiêu đề',
    minWidth: 280,
    type: 'text'
  },
  {
    prop: 'distance',
    label: 'Khoảng cách',
    minWidth: 150,
    type: 'number'
  },
  {
    prop: 'unit',
    label: 'Đơn vị',
    minWidth: 150,
    type: 'select',
    options: [{
      value: 'km',
      title: 'km'
    },
    {
      value: 'step',
      title: 'bước'
    },
    {
      value: 'times',
      title: 'cái'
    }]
  }
]
